import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import icon from "../images/icon-marketingpr.svg";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const StaticPage = ({ data }) => {

  const { body } = data.contentfulStaticPage;


  return (
    <Layout>
      <SEO title="Lawtech Hub Marketing and PR" />
      <div className="">
        <Img
          className="object-cover w-full h-64"
          fluid={data.file.childImageSharp.fixed}
          alt="Header image"
        />
      </div>
      <div className="container">
      <h1 className="title">Marketing & PR Guide</h1>
      </div>
      <div className="bg-white w-full border-t-2 border-b border-white">
        <div className="container flex py-6 my-2 self-center">
          <div className="w-auto lg:w-1/2 self-center">
            <h3 className="pt-6 text-center md:text-left">
              {documentToReactComponents(data.contentfulPageIntro.intro.json)}
            </h3>
          </div>
          <div className="w-1/2 hidden lg:block">
            <img src={icon} className="h-64 mb-0 float-right mx-12" alt="LawTech Hub Icons" />
          </div>
        </div>
      </div>



      <div className="py-8">
        <div class="container bg-white shadow-md rounded-md p-20 mb-24 static-page-body">
        
        <div className="text-base whitespace-pre-wrap"
                  dangerouslySetInnerHTML={{
                    __html: body.childMarkdownRemark.html,
                  }}
                />


        </div>
      </div>

    </Layout>
  );
};




export const query = graphql`
  query marketingPRPage {
    contentfulPageIntro(page: { eq: "MarketingPR" }) {
        intro {
          json
        } 
      }
    
    contentfulStaticPage(title: { eq: "MarketingPR" }) {
      body {
        childMarkdownRemark {
          html
        }
      }
    }
      
   
    file(relativePath: { eq: "banner-marketingpr.jpg" }) {
      childImageSharp {
        fixed(width: 1200) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }    
  }



`;



export default StaticPage;